import React, { useRef, useEffect, useState } from 'react';
import { GameScene } from './GameScene';
import NavBar from './NavBar';
import ContentDisplay from './ContentDisplay';
import SocialLinks from './SocialLinks';
import { CSSTransition } from 'react-transition-group';

const SplashPage: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const gameSceneRef = useRef<GameScene | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [showContent, setShowContent] = useState(false);
  const [copiedFeedback, setCopiedFeedback] = useState<{ text: string; x: number; y: number } | null>(null);
  const nodeRef = useRef(null);  // Add this line

  useEffect(() => {
    if (!mountRef.current) return;

    const handleCopy = (text: string, x: number, y: number) => {
      setCopiedFeedback({ text, x, y });
      setTimeout(() => setCopiedFeedback(null), 1500);
    };

    gameSceneRef.current = new GameScene(mountRef.current, handleCopy);

    const handleResize = () => {
      gameSceneRef.current?.handleResize();
    };

    window.addEventListener('resize', handleResize);

    const animate = (time: number) => {
      gameSceneRef.current?.animate(time);
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);

    const currentMountRef = mountRef.current;

    return () => {
      window.removeEventListener('resize', handleResize);
      if (gameSceneRef.current && currentMountRef) {
        gameSceneRef.current.cleanup(currentMountRef);
      }
    };
  }, []);

  useEffect(() => {
    setShowContent(!!activeTab);
  }, [activeTab]);

  const handleSetActiveTab = (tab: string | null) => {
    setActiveTab(tab);
  };

  return (
    <div className="splash-container">
      <div 
        ref={mountRef} 
        className="three-container"
      />
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000 }}>
        <NavBar setActiveTab={handleSetActiveTab} activeTab={activeTab} />
      </div>
      <CSSTransition
        in={showContent}
        timeout={300}
        classNames="fade"
        unmountOnExit
        nodeRef={nodeRef}  // Add this line
      >
        <div ref={nodeRef}>  {/* Wrap ContentDisplay with this div */}
          <ContentDisplay activeTab={activeTab} />
        </div>
      </CSSTransition>
      <div style={{ position: 'absolute', bottom: 20, left: 0, right: 0, zIndex: 1000 }}>
        <SocialLinks />
      </div>
      {copiedFeedback && (
        <div 
          className="copied-feedback"
          style={{
            position: 'fixed',
            left: copiedFeedback.x,
            top: copiedFeedback.y,
            transform: 'translate(-50%, -100%)'
          }}
        >
          Copied: {copiedFeedback.text}
        </div>
      )}
    </div>
  );
};

export default SplashPage;