import React from 'react';

const ProjectsSection: React.FC = () => {
  return (
    <div className="content-section projects-section">
      <h2>Projects</h2>
      <div className="projects-content">
        {[{
          title: "Deep Impact - Fall In, Galactic Vets Hackathon",
          date: "May 2024",
          description: [
            "Achieved 1st place by deploying a working prototype within 48 hours using React frontend and Django backend",
            "Led the development of an educational website to teach general audiences about asteroid collisions and Earth's defense capabilities",
            "Featured a simplified orbital mechanics simulation and collision scenarios using NASA's Sentry API data"
          ]
        },
        {
          title: "Personal Website",
          date: "AUG 2022",
          description: [
            "Designed a novel 3D personal website/tech demo using ThreeJS and React",
            "Combined UX/UI Principles and skeuomorphism to create an intuitive user-friendly 3D Website",
            "Designed and optimized 3D models in Blender to maximize performance in web-browsers on mobile and desktop",
            "Available at: https://smithdepazd.github.io/"
          ]
        },
        {
          title: "Silbo Gomero Audio - Linguistics project",
          date: "AUG 2021",
          description: [
            "Worked internationally with linguistics experts to help preserve an esoteric whistling language",
            "Researched the fundamental mechanics of the language and designed an algorithm to use spectrogram analysis to convert whistled speech into readable images for education"
          ]
        },
        {
          title: "AI Circuit design - Senior Design",
          date: "AUG 2020",
          description: [
            "Compared the viability of two machine Learning algorithms' competency at creating gate-level circuits",
            "Created and implemented a Q-learning AI that utilizes Monte Carlo policy evaluation"
          ]
        },
        {
          title: "Random Robes - Personal Project",
          date: "FEB 2020",
          description: [
            "Released a publicly available free mod to the PC game Noita",
            "Implemented a system in Python to autonomously generate color pallets, create custom 2D character models, and update Lua code of the game files",
            "Achieved over 50k concurrent users"
          ]
        },
        {
          title: "BasilESC - Personal Project",
          date: "APR 2018",
          description: [
            "Developed a dungeon crawler game in C, running on an Arduino Uno with a 16x32 LED display and NES controller",
            "Features included procedurally generated levels, player customization, boss levels, wall breaking, and teleporting mechanics"
          ]
        }
        ].map((project, index) => (
          <div key={index} className="project-item">
            <h3>{project.title}</h3>
            <p>{project.date}</p>
            <ul>
              {project.description.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;