import * as THREE from 'three';
import CRTShader from './CRTShader';

const PlaneShader = {
  uniforms: {
    time: { value: 0 },
    transitionProgress: { value: 0 },
    isHovered: { value: 0 },
    mousePosition: { value: new THREE.Vector2() },
    resolution: { value: new THREE.Vector2() },
    hoverRadius: { value: 1.0 },
    isClicked: { value: 0 },
    animationProgress: { value: 0 },
    maxRingSize: { value: 2.0 },
    maxSpinSpeed: { value: 50.0 },
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform float transitionProgress;
    uniform float isHovered;
    uniform vec2 mousePosition;
    uniform vec2 resolution;
    uniform float hoverRadius;
    uniform float isClicked;
    uniform float animationProgress;
    uniform float maxRingSize;
    uniform float maxSpinSpeed;
    varying vec2 vUv;

    ${CRTShader}

    float sdTorus(vec3 p, vec2 t) {
      vec2 q = vec2(length(p.xz) - t.x, p.y);
      return length(q) - t.y;
    }

    vec3 rotateY(vec3 p, float angle) {
      float c = cos(angle);
      float s = sin(angle);
      return vec3(c * p.x + s * p.z, p.y, -s * p.x + c * p.z);
    }

    vec3 rotateX(vec3 p, float angle) {
      float c = cos(angle);
      float s = sin(angle);
      return vec3(p.x, c * p.y - s * p.z, s * p.y + c * p.z);
    }

    float applyScanline(vec2 uv, float time) {
      return abs(sin(uv.y * 1600.0 + time * 10.0));
    }

    void main() {
      vec2 uv = vUv * 2.0 - 1.0;
      
      vec3 rayOrigin = vec3(0.0, 0.0, -2.0);
      vec3 rayDirection = normalize(vec3(uv, 1.0));

      float baseSpinSpeed = 0.75;
      float accelerationFactor = pow(transitionProgress, 2.0) * 10.0;
      float clickedSpinSpeed = min(baseSpinSpeed + isClicked * accelerationFactor, maxSpinSpeed);
      float spinAngle = time * clickedSpinSpeed;
      vec3 torusCenter = vec3(0.0, 0.1, 0.0);

      float baseRadius = 0.25;
      float baseThickness = 0.06;

      float maxGrowth = 0.1;
      float hoverGrowth = 1.0 + maxGrowth * animationProgress;
      
      float radiusGrowth = mix(baseRadius, maxRingSize, pow(transitionProgress*0.5, 2.0)) * hoverGrowth;
      
      float maxThickness = maxRingSize * 0.4;
      float thicknessGrowth = mix(baseThickness, maxThickness, pow(transitionProgress, 1.5)) * hoverGrowth;
      
      vec2 torusParams = vec2(radiusGrowth, thicknessGrowth);
      
      float t = 0.0;
      float d;
      for(int i = 0; i < 64; i++) {
        vec3 p = rayOrigin + rayDirection * t - torusCenter;
        p = rotateY(p, spinAngle);
        p = rotateX(p, radians(90.0));
        d = sdTorus(p, torusParams);
        if(abs(d) < 0.001) break;
        t += d;
        if(t > 5.0) break;
      }

      vec4 finalColor = vec4(0.0, 0.0, 0.0, 1.0); // Opaque black background
      
      if(t < 5.0) {
        float crtIntensity = 1.0 - transitionProgress;
        float scanline = applyScanline(vUv, time);
        float alpha = scanline * crtIntensity * 0.1; // Scanline intensity determines opacity

        finalColor = vec4(0.0, 0.0, 0.0, alpha); // Transparent black with scanline opacity
      }

      gl_FragColor = finalColor;
    }
  `,
};

export default PlaneShader;