export class HoverDetector {
  private hoverRadius: number;
  private aspectRatio: number;

  constructor(hoverRadius: number, aspectRatio: number) {
    this.hoverRadius = hoverRadius;
    this.aspectRatio = aspectRatio;
  }

  updateAspectRatio(aspectRatio: number) {
    this.aspectRatio = aspectRatio;
  }

  isHovering(mouseX: number, mouseY: number): boolean {
    const distanceX = mouseX * this.aspectRatio;
    const distanceY = mouseY;
    const distanceSquared = distanceX * distanceX + distanceY * distanceY;
    return distanceSquared <= this.hoverRadius * this.hoverRadius;
  }
}