import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { createSkyDome } from './sceneObjects';
import { createSkyMaterial, createPlaneMaterial } from './ShaderMaterials';
import { HoverDetector } from './HoverDetector';
import TorusShader from './shaders/TorusShader';
import FloatingTextShader from './shaders/FloatingTextShader';

export class GameScene {
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  composer: EffectComposer;
  bloomPass: UnrealBloomPass;
  plane?: THREE.Mesh;
  mouse: THREE.Vector2;
  hoverDetector: HoverDetector;
  raycaster: THREE.Raycaster;
  lightPosition: THREE.Vector3;
  floatingText: THREE.Mesh;
  private floatingTexts: string[];
  private clipboardTexts: string[];
  private currentTextIndex: number;
  private isHovering: boolean;
  private onCopyCallback: (text: string, x: number, y: number) => void;

  constructor(mountElement: HTMLDivElement, onCopyCallback: (text: string, x: number, y: number) => void) {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    mountElement.appendChild(this.renderer.domElement);

    this.composer = new EffectComposer(this.renderer);
    this.bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      0.5,  // Bloom strength
      1.0,  // Radius
      0.1   // Threshold
    );

    this.mouse = new THREE.Vector2();
    const hoverRadius = 0.7;
    const aspectRatio = window.innerWidth / window.innerHeight;
    this.hoverDetector = new HoverDetector(hoverRadius, aspectRatio);
    this.raycaster = new THREE.Raycaster();
    this.lightPosition = new THREE.Vector3(5, 5, 5);

    this.floatingTexts = [
      '@adeadzeplin',
      'smithdepazd@gmail.com',
      'github',
      'linkedin',
      'Thanks for visiting'
    ];
    this.clipboardTexts = [
      '@adeadzeplin',
      'smithdepazd@gmail.com',
      'https://github.com/adeadzeplin',
      'https://www.linkedin.com/in/daniel-smith-depaz/',
      'smithdepazd@gmail.com'
    ];
    this.currentTextIndex = 0;
    this.isHovering = false;
    this.onCopyCallback = onCopyCallback;

    this.initializeScene();
    this.plane = this.createPlane();
    this.floatingText = this.createFloatingText();
    this.scene.add(this.floatingText);

    this.setupPostProcessing();

    mountElement.addEventListener('mousemove', this.handleMouseMove.bind(this));
    mountElement.addEventListener('click', this.handleClick);  // Changed this line
  }

  private initializeScene() {
    const skyDome = createSkyDome(createSkyMaterial());
    this.scene.add(skyDome);

    this.camera.position.set(0, 0, 0);
    this.camera.lookAt(0, 0, -5);
  }

  private createPlane() {
    const planeGeometry = new THREE.PlaneGeometry(20, 20);
    const planeMaterial = createPlaneMaterial();
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.position.set(0, -0.5, -5);
    plane.rotation.x = 0;//Math.PI / 4;
    this.scene.add(plane);
    return plane;
  }

  private createFloatingText(): THREE.Mesh {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 512;
    canvas.height = 128;

    if (context) {
      context.fillStyle = '#000000';  // Black text
      context.font = 'bold 48px Arial';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(this.floatingTexts[this.currentTextIndex], canvas.width / 2, canvas.height / 2);
    }

    const texture = new THREE.CanvasTexture(canvas);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        textTexture: { value: texture },
        glowColor: { value: new THREE.Color(0, 0, 0) },  // Black glow color
        glowStrength: { value: 1.5 }
      },
      vertexShader: FloatingTextShader.vertexShader,
      fragmentShader: FloatingTextShader.fragmentShader,
      transparent: true,
      side: THREE.DoubleSide,
    });

    const geometry = new THREE.PlaneGeometry(4, 1);  // Increased size
    const textMesh = new THREE.Mesh(geometry, material);
    textMesh.position.set(0, 0, -2);  // Adjusted position
    textMesh.scale.set(1.5, 1.5, 1.5);  // Increased scale
    textMesh.visible = true;
    return textMesh;
  }

  private updateFloatingText() {
    if (this.floatingText && this.floatingText.material instanceof THREE.ShaderMaterial) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = 1024;
      canvas.height = 256;

      if (context) {
        context.fillStyle = '#000000';  // Black text
        context.font = 'bold 48px Arial';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(this.floatingTexts[this.currentTextIndex], canvas.width / 2, canvas.height / 2);
      }

      const texture = new THREE.CanvasTexture(canvas);
      this.floatingText.material.uniforms.textTexture.value = texture;
      this.floatingText.material.uniforms.glowColor.value.setRGB(0, 0, 0);  // Ensure glow color is black
    }
  }

  private setupPostProcessing() {
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    this.bloomPass.threshold = 0.1;
    this.bloomPass.strength = 0.0;
    this.bloomPass.radius = 2.0;
    this.composer.addPass(this.bloomPass);
  }

  animate(time: number) {
    if (this.plane?.material instanceof THREE.ShaderMaterial) {
      this.plane.material.uniforms.time.value = time * 0.001;
    }

    const skyObject = this.scene.getObjectByName('sky');
    if (skyObject instanceof THREE.Mesh && skyObject.material instanceof THREE.ShaderMaterial) {
      skyObject.material.uniforms.time.value = time * 0.001;
    }

    this.lightPosition.x = Math.sin(time * 0.001) * 5;
    this.lightPosition.y = Math.cos(time * 0.001) * 5 + 5;
    this.lightPosition.z = Math.sin(time * 0.0005) * 5;

    if (this.floatingText && this.floatingText.material instanceof THREE.ShaderMaterial) {
      this.floatingText.visible = this.isHovering;  // Only visible when hovering
      if (this.isHovering) {
        this.floatingText.position.set(0, Math.sin(time * 0.002) * 0.1, -8);
        this.floatingText.quaternion.copy(this.camera.quaternion);
        this.floatingText.rotateX(-0.5);
        const glowStrength = 1.5 + Math.sin(time * 0.005) * 0.2;
        this.floatingText.material.uniforms.glowStrength.value = glowStrength;
      }
    }

    this.composer.render();
  }

  handleMouseMove(event: MouseEvent) {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const isHovering = this.hoverDetector.isHovering(this.mouse.x, this.mouse.y);

    if (isHovering && !this.isHovering) {
      // Mouse just entered hover state
      this.currentTextIndex = (this.currentTextIndex + 1) % this.floatingTexts.length;
      this.updateFloatingText();
      this.isHovering = true;
      document.body.style.cursor = 'pointer';
      this.bloomPass.strength = 0.5;
      this.floatingText.visible = true;  // Make text visible
    } else if (!isHovering && this.isHovering) {
      // Mouse just left hover state
      this.isHovering = false;
      document.body.style.cursor = 'default';
      this.bloomPass.strength = 0;
      this.floatingText.visible = false;  // Make text invisible
    }

    if (this.plane?.material instanceof THREE.ShaderMaterial) {
      const planeMaterial = this.plane.material;
      planeMaterial.uniforms.mousePosition.value.set(event.clientX, event.clientY);
      planeMaterial.uniforms.isHovered.value = isHovering ? 1 : 0;  // Add this line
    }
  }

  private handleClick = (event: MouseEvent) => {
    if (this.isHovering) {
      const textToCopy = this.clipboardTexts[this.currentTextIndex];
      
      const fallbackCopyTextToClipboard = (text: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          this.showCopiedFeedback();
          if (this.onCopyCallback) {
            this.onCopyCallback(textToCopy, event.clientX, event.clientY);
          }
        } catch (err) {
          console.error('Fallback: Unable to copy', err);
        }
        document.body.removeChild(textArea);
      };

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(textToCopy);
      } else {
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.showCopiedFeedback();
          if (this.onCopyCallback) {
            this.onCopyCallback(textToCopy, event.clientX, event.clientY);
          }
        }).catch(err => {
          console.warn('Clipboard API failed, trying fallback method', err);
          fallbackCopyTextToClipboard(textToCopy);
        });
      }
    }
  };

  private showCopiedFeedback() {
    if (this.floatingText && this.floatingText.material instanceof THREE.ShaderMaterial) {
      const material = this.floatingText.material;
      const originalColor = material.uniforms.glowColor.value.clone();
      const originalStrength = material.uniforms.glowStrength.value;

      // Change color to green and increase glow strength
      material.uniforms.glowColor.value.setRGB(0, 1, 0);
      material.uniforms.glowStrength.value = 2.0;

      // Reset after 500 milliseconds (0.5 seconds)
      setTimeout(() => {
        material.uniforms.glowColor.value.copy(originalColor);
        material.uniforms.glowStrength.value = originalStrength;
      }, 500);
    }
  }

  handleResize() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);

    if (this.plane?.material instanceof THREE.ShaderMaterial) {
      this.plane.material.uniforms.resolution.value.set(width, height);
    }

    this.hoverDetector.updateAspectRatio(width / height);
  }

  cleanup(mountElement: HTMLDivElement) {
    mountElement.removeChild(this.renderer.domElement);
    mountElement.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    mountElement.removeEventListener('click', this.handleClick.bind(this));
  }
}