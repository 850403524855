import React from 'react';
import { motion } from 'framer-motion';

const AboutSection: React.FC = () => {
  return (
    <div className="content-section about-section">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        Dan  Smith-dePaz
      </motion.h2>
      <div className="about-content">
        <motion.div 
          className="about-intro"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <p>A passionate Software Engineer with a knack for solving complex problems and creating innovative solutions.</p>
          <p>Currently looking for interesting work</p>
        </motion.div>
        <motion.div 
          className="about-details"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h4>Areas of Interest</h4>
          <ul>
            <li>Embedded systems and Baremetal design</li>
            <li>Full-stack development with React, TypeScript, and Django</li>
            <li>3D graphics programming with WebGL/Unreal Engine 5</li>
            <li>Robotics and Automation</li>
          </ul>
        </motion.div>
        <motion.div 
          className="about-background"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h4>Background</h4>
          <p>Obsessed with math/computers/automation since I was 12. 
            I decided to do FTC robotics in highschool. After 3 years of robotics club, I decided to persue a BS in Computer Engineering from Wentworth Institute of Technology.
            Graduated in 2020 and have been continuously teaching myself new skills ever since.
           </p>
        </motion.div>
        <motion.div 
          className="about-passion"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <h4>Passion</h4>
          <p></p>
          <p>I'm driven by the endless possibilities of technology and its power to make a positive impact. Whether it's crafting intuitive user interfaces, optimizing backend systems, or diving into cutting-edge tech, I'm always eager to learn and push the boundaries of what's possible.</p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutSection;