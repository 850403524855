import * as THREE from 'three';
import SkyShader from './shaders/SkyShader';
import PlaneShader from './shaders/PlaneShader';

export const createSkyMaterial = (): THREE.ShaderMaterial => {
  return new THREE.ShaderMaterial({
    vertexShader: SkyShader.vertexShader,
    fragmentShader: SkyShader.fragmentShader,
    uniforms: SkyShader.uniforms,
    side: THREE.BackSide
  });
};

export const createPlaneMaterial = (): THREE.ShaderMaterial => {
  return new THREE.ShaderMaterial({
    vertexShader: PlaneShader.vertexShader,
    fragmentShader: PlaneShader.fragmentShader,
    uniforms: {
      ...PlaneShader.uniforms,
      resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
    },
    transparent: true,
    depthWrite: false,
  });
};