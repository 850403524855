import * as THREE from 'three';
import CloudShader from './CloudShader';

const SkyShader = {
  uniforms: {
    time: { value: 0 },
    cloudScale: { value: 1.0 },
    cloudSpeed: { value: 0.1 },
    windDirection: { value: new THREE.Vector3(1, 0, 0) },
    windSpeed: { value: 1.5 },
    skyColor: { value: new THREE.Color(0x4169E1) }, // Royal Blue color
  },
  vertexShader: `
    varying vec3 vWorldPosition;
    void main() {
      vec4 worldPosition = modelMatrix * vec4(position, 1.0);
      vWorldPosition = worldPosition.xyz;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform float cloudScale;
    uniform float cloudSpeed;
    uniform vec3 windDirection;
    uniform float windSpeed;
    uniform vec3 skyColor;
    varying vec3 vWorldPosition;
    
    ${CloudShader}

    void main() {
      vec3 viewDirection = normalize(vWorldPosition);
      
      // Use the uniform skyColor
      vec3 baseSkyColor = skyColor;
      
      // Add a subtle gradient to the sky
      vec3 gradientColor = vec3(skyColor.r * 1.2, skyColor.g * 1.1, skyColor.b);
      baseSkyColor = mix(baseSkyColor, gradientColor, smoothstep(-0.2, 0.2, viewDirection.y));
      
      vec3 clouds = generateClouds(viewDirection * cloudScale, time * cloudSpeed, windDirection, windSpeed, skyColor);
      
      vec3 finalColor = mix(baseSkyColor, clouds, 0.7);
      
      gl_FragColor = vec4(finalColor, 1.0);
    }
  `,
};

export default SkyShader;