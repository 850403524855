import React from 'react';
import AboutSection from './AboutSection';
import ProjectsSection from './ProjectsSection';
import ResumeSection from './ResumeSection';

interface ContentDisplayProps {
  activeTab: string | null;
}

const ContentDisplay: React.FC<ContentDisplayProps> = ({ activeTab }) => {
  if (!activeTab) return null;

  return (
    <div className="content-display">
      {activeTab === 'About' && <AboutSection />}
      {activeTab === 'Projects' && <ProjectsSection />}
      {activeTab === 'Resume' && <ResumeSection />}
    </div>
  );
};

export default ContentDisplay;