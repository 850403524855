import React from 'react';
import { motion } from 'framer-motion';

interface NavBarProps {
  setActiveTab: (tab: string | null) => void;
  activeTab: string | null;
}

const NavBar: React.FC<NavBarProps> = ({ setActiveTab, activeTab }) => {
  const tabs = ['About', 'Projects', 'Resume'];

  const handleTabClick = (tab: string) => {
    if (activeTab === tab) {
      setActiveTab(null); // Close the tab if it's already active
    } else {
      setActiveTab(tab); // Open the clicked tab
    }
  };

  return (
    <nav className="nav-bar">
      {tabs.map((tab) => (
        <motion.button
          key={tab}
          className={`nav-item ${activeTab === tab ? 'active' : ''}`}
          onClick={() => handleTabClick(tab)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {tab}
        </motion.button>
      ))}
    </nav>
  );
};

export default NavBar;