import * as THREE from 'three';

const GrassShader = {
  vertexShader: `
    uniform float time;
    uniform vec3 customCameraPosition;
    attribute float grassIndex;
    attribute float randomRotation;
    varying vec2 vUv;
    varying vec3 vColor;

    void main() {
      vec4 worldPosition = instanceMatrix * vec4(position, 1.0);
      
      // Adjust the grass height
      worldPosition.y += 0.2; // Change this value to fine-tune the grass height

      // Apply random rotation
      float s = sin(randomRotation);
      float c = cos(randomRotation);
      mat2 rotationMatrix = mat2(c, -s, s, c);
      worldPosition.xz = rotationMatrix * worldPosition.xz;

      float distance = distance(worldPosition.xyz, customCameraPosition);
      float updateFreq = mix(0.05, 3.3333, clamp(distance * 0.01, 0.0, 1.0));
      float lastUpdateTime = floor(time / updateFreq) * updateFreq;

      // Simple wind effect
      float wind = sin(lastUpdateTime * 2.0 + worldPosition.x * 0.5 + worldPosition.z * 0.5) * 0.05;
      worldPosition.x += wind * uv.y;

      gl_Position = projectionMatrix * modelViewMatrix * worldPosition;

      vUv = uv;

      // Calculate color
      float distanceFactor = clamp(distance / 100.0, 0.0, 1.0);
      vec3 baseColor = mix(vec3(0.2285, 0.9922, 0.1387), vec3(0.2285, 0.3647, 0.1387), distanceFactor);
      float colorNoise = fract(sin(dot(worldPosition.xz, vec2(12.9898, 78.233))) * 43758.5453);
      vColor = mix(baseColor, vec3(colorNoise), 0.1);

      // Debug: Uncomment to pass update progress to fragment shader
      // float vUpdateProgress = (time - lastUpdateTime) / updateFreq;
    }
  `,
  fragmentShader: `
    varying vec2 vUv;
    varying vec3 vColor;

    void main() {
      vec3 color = vColor;

      // Debug: Uncomment to enable debug modes
      // uniform bool showLOD;
      // uniform bool debugMode;
      // varying float vUpdateProgress;
      // if (debugMode || showLOD) {
      //   color = vec3(1.0 - vUpdateProgress);
      // }

      float alpha = smoothstep(0.7, 0.95, vUv.y);
      gl_FragColor = vec4(color, alpha);
    }
  `,
  uniforms: {
    time: { value: 0 },
    customCameraPosition: { value: new THREE.Vector3() },
    // Debug: Uncomment to enable debug uniforms
    // showLOD: { value: false },
    // debugMode: { value: false }
  }
};

export default GrassShader;