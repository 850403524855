import * as THREE from 'three';
import GrassShader from './shaders/GrassShader';

// Creates a sky dome with the given material
export const createSkyDome = (material: THREE.Material): THREE.Mesh => {
  const geometry = new THREE.SphereGeometry(500, 64, 64);
  const skyDome = new THREE.Mesh(geometry, material);
  skyDome.name = 'sky';
  return skyDome;
};

// Creates a ground plane
export const createGround = (): THREE.Mesh => {
  const geometry = new THREE.PlaneGeometry(100, 100);
  const material = new THREE.MeshStandardMaterial({ color: 0x3a9d23 });
  const ground = new THREE.Mesh(geometry, material);
  ground.rotation.x = -Math.PI / 2; // Rotate to lay flat
  return ground;
};

// Creates a player character (simple capsule shape)
export const createPlayer = (): THREE.Mesh => {
  const geometry = new THREE.CapsuleGeometry(0.5, 1, 4, 8);
  const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
  const player = new THREE.Mesh(geometry, material);
  player.position.set(0, 1, 0); // Slightly above ground
  return player;
};

// Creates a plane with the given material (used for special effects)
export const createPlane = (material: THREE.Material): THREE.Mesh => {
  const geometry = new THREE.PlaneGeometry(20, 20);
  const plane = new THREE.Mesh(geometry, material);
  plane.rotation.x = -Math.PI / 2; // Lay flat
  plane.position.y = 0.01; // Slightly above ground to prevent z-fighting
  return plane;
};

// Creates instanced grass for better performance
export const createGrassLOD = (): THREE.InstancedMesh[] => {
  const grassGeometry = new THREE.PlaneGeometry(0.1, 0.5);
  const grassMaterial = new THREE.ShaderMaterial(GrassShader);
  const grassInstanceCount = 500000;
  const grassGroup = new THREE.InstancedMesh(grassGeometry, grassMaterial, grassInstanceCount);

  const dummy = new THREE.Object3D();
  const gridSize = 100;

  // Position grass instances randomly within the grid
  for (let i = 0; i < grassInstanceCount; i++) {
    const x = (Math.random() - 0.5) * gridSize;
    const z = (Math.random() - 0.5) * gridSize;
    dummy.position.set(x, 0, z);
    dummy.scale.setScalar(0.5 + Math.random() * 0.5); // Random scale for variety
    dummy.updateMatrix();
    grassGroup.setMatrixAt(i, dummy.matrix);
  }

  grassGroup.instanceMatrix.needsUpdate = true;
  return [grassGroup];
};