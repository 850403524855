import * as THREE from 'three';

const FloatingTextShader = {
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D textTexture;
    uniform float glowStrength;
    uniform vec3 glowColor;
    varying vec2 vUv;

    void main() {
      vec4 texColor = texture2D(textTexture, vUv);
      
      // Apply glow effect
      float alpha = texColor.a;
      float glowFactor = smoothstep(0.0, 0.5, alpha) * glowStrength;
      
      // Combine text color with glow
      vec3 color = mix(vec3(0.0), glowColor, glowFactor);
      
      gl_FragColor = vec4(color, alpha + glowFactor);
    }
  `,
  uniforms: {
    textTexture: { value: null },
    glowStrength: { value: 0.5 },
    glowColor: { value: new THREE.Color(0x000000) }
  }
};

export default FloatingTextShader;